import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { format } from 'date-fns'

import { fetch } from '~/utils/fetch'

const isMaintenanceMode = () => {
  return Boolean(
    parseInt(
      (typeof window !== 'undefined' && window.ENV?.MAINTENANCE_MODE) ||
        (typeof process !== 'undefined' && process.env.MAINTENANCE_MODE) ||
        '0',
      10
    )
  )
}

const isGraphqlLoggingEnabled = () => {
  const logSetting = (typeof process !== 'undefined' && process.env.LOG_GRAPHQL_QUERIES) || '0'
  return logSetting === '1'
}

export type ResponseHeaders = {
  [key: string]: string | string[] // Each header can be a string or an array of strings for multiple values
}

const createHttpLink = (request: Request | null, responseHeaders?: ResponseHeaders) => {
  const fetchWithCookies = async (url: Request | string | URL, options: RequestInit | undefined) => {
    if (request && request.headers.get('cookie')) {
      options = options || {}
      options.headers = {
        ...options.headers,
        cookie: request.headers.get('cookie') || ''
      }
    }

    // Fetch with the cookie header
    const response = await fetch(url, options)

    // Capture any headers you want to pass back, including 'set-cookie' and 'X-Auth-Code'
    const setCookieHeader = response.headers.get('set-cookie')
    const authCodeHeader = response.headers.get('X-Auth-Code')

    if (setCookieHeader && responseHeaders) {
      responseHeaders['set-cookie'] = setCookieHeader
    }

    if (authCodeHeader && responseHeaders) {
      responseHeaders['X-Auth-Code'] = authCodeHeader
    }

    return response
  }

  return new HttpLink({
    uri: (typeof window !== 'undefined' && window.ENV?.GRAPHQL_URL) || process.env.GRAPHQL_URL || '',
    fetch: fetchWithCookies,
    credentials: 'include'
  })
}

const createAuthLink = (request: Request | null, customHeaders: Record<string, string> = {}) => {
  return setContext((_, { headers }) => {
    let token = null
    const cookies = request ? request.headers.get('cookie') : typeof document !== 'undefined' ? document.cookie : ''

    if (cookies) {
      const cookieArray = cookies.split('; ')
      for (let cookie of cookieArray) {
        if (cookie.startsWith('pevanandsarah-session=')) {
          token = cookie.split('=')[1]
          break
        }
      }
    }

    return {
      headers: {
        ...headers,
        ...customHeaders, // Include the custom headers here
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
})

const maintenanceLink = new ApolloLink((operation, forward) => {
  if (isMaintenanceMode()) {
    return null
  }
  return forward(operation)
})

const createLogLink = () => {
  return new ApolloLink((operation, forward) => {
    const startTime = Date.now()
    const operationName = operation.operationName || 'unnamed operation'

    if (isGraphqlLoggingEnabled()) {
      // Detailed logging when enabled
      console.log(`GraphQL Request [${operationName}]:`, {
        query: operation.query,
        variables: operation.variables,
        operationName: operation.operationName,
        context: operation.getContext()
      })
    }

    return forward(operation).map(response => {
      const duration = Date.now() - startTime

      if (isGraphqlLoggingEnabled()) {
        // Detailed response logging when enabled
        console.log(`GraphQL Response [${operationName}]:`, {
          data: response.data,
          errors: response.errors,
          extensions: response.extensions,
          duration: `${duration}ms`
        })
      } else {
        // Minimal logging by default
        const timestamp = `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}.${String(new Date().getMilliseconds()).padStart(3, '0')}`
        console.log(`${timestamp} [info]: GraphQL [${operationName}] ${response.errors ? 'ERROR' : 'OK'} ${duration}ms`)
        if (response.errors) {
          console.error(`${timestamp} [error] GraphQL [${operationName}] errors:`, response.errors)
        }
      }

      return response
    })
  })
}

// responseHeaders is an object passed by reference, that will be populated with
// any headers that need to be passed back to the client
export const createApolloClient = (
  request: Request | null,
  responseHeaders?: ResponseHeaders,
  customHeaders?: Record<string, string>
) => {
  const httpLink = createHttpLink(request, responseHeaders)
  const authLink = createAuthLink(request, customHeaders)
  const logLink = createLogLink()

  return new ApolloClient({
    link: from([maintenanceLink, authLink, errorLink, logLink, httpLink]),
    cache: new InMemoryCache()
  })
}
