import crossFetch from 'cross-fetch'
import fetchCookie from 'fetch-cookie'

const isFetchHeaderLoggingEnabled = () => {
  const logSetting = (typeof process !== 'undefined' && process.env.LOG_FETCH_HEADERS) || '0'
  return logSetting === '1'
}

export const fetch = (url: Request | string | URL, options: RequestInit | undefined) => {
  // Log the headers here
  if (isFetchHeaderLoggingEnabled()) {
    console.log('Fetch Request Headers:', url, options?.headers)
  }

  // Call the original fetch function
  return fetchCookie(crossFetch)(url, options)
}
